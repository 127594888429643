<template>
  <vue-app>
    <Dashboard v-if="!noNavBar && ready()" />
    <SnackBar />
    <SessionModal v-if="!noNavBar" :isIdle="isIdle" />
    <Version />
    <Vidle
      @idle="onIdle"
      @refresh="onRefresh"
      :duration="idleTime"
      :syncKey="merchantID"
    />
  </vue-app>
</template>

<script>
import Vidle from 'v-idle';
import Vue from 'vue';

import Dashboard from './components/Dashboard.vue';
import SessionModal from './components/SessionModal.vue';
import store from './store';

import SnackBar from '@/components/SnackBar';
import Version from '@/components/Version';

export default {
  components: { Dashboard, SnackBar, SessionModal, Version, Vidle },
  data: () => ({
    isIdle: false,
  }),
  methods: {
    ready() {
      return Vue.prototype.$config;
    },
    onIdle() {
      this.isIdle = true;
    },
    onRefresh() {
      this.isIdle = false;
    },
  },
  computed: {
    noNavBar() {
      return (
        Vue.prototype.$noNavBar ||
        this.$route.name === 'login' ||
        this.$route.name === 'forget-password'
      );
    },
    idleTime() {
      return store.getters['dashboard/getIdleTime'];
    },
    merchantID() {
      return Vue.prototype.$merchant || window.location.hostname;
    },
  },
};
</script>
